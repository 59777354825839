// File: src/App.js
import React, { useState, useEffect } from 'react';
import './App.css';

const App = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [password, setPassword] = useState('');
  const [iframeVisible, setIframeVisible] = useState(false);
  const [loading, setLoading] = useState(false);

  // Check for login status on component mount
  useEffect(() => {
    const loggedIn = localStorage.getItem('isAuthenticated');
    if (loggedIn) {
      setIsAuthenticated(true);
    }
  }, []);

  const handlePasswordSubmit = (e) => {
    e.preventDefault();
    if (password.toLowerCase() === 'vamos') {
      setIsAuthenticated(true);
      localStorage.setItem('isAuthenticated', 'true'); // Store login status
    } else {
      alert('Incorrect password');
    }
  };


   // Listen for close messages from Humantix iframe
   useEffect(() => {
    const handleMessage = (event) => {
      // Check if message is from Humantix and is the close event
      if (event.data?.type === "HX_WIDGETS_V2_POPUP_CLOSE") {
        setIframeVisible(false); // Close iframe when message received
      }
    };

    window.addEventListener("message", handleMessage);

    // Cleanup listener on component unmount
    return () => window.removeEventListener("message", handleMessage);
  }, []);


  return (
    <div className={`App ${iframeVisible ? 'iframe-open' : ''}`}>
      {!isAuthenticated ? (
        <div className="landing-page">
          <h1>STAKE CUP 2025</h1>
          <form onSubmit={handlePasswordSubmit}>
            <input
              type="password"
              placeholder="Enter password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <button type="submit">Enter</button>
          </form>
        </div>
      ) : (
        <div className="event-page">
       <section>
  <h2>STAKE CUP 2025 - 25. JANUAR</h2>
  <p>
    Velkommen til STAKE CUP 2025 – årets høydepunkt innen padel! Etter en fantastisk
    første turnering i 2024, er vi klare for å nok en runde med aktivtet og moro. STAKE CUP er 
    en turnering som inviterer alle – både nybegynnere og erfarne padelspillere – til en dag fylt 
    med spenning, konkurranse og moro. Turneringen arrangeres i forbindelse med min bursdag, og 
    jeg gleder meg til å samle både gamle og nye deltakere for å feire med stil.
    <br />
    {/* Inserted image */}
  <img src="/SC2024Wide.jpg" alt="Stake Cup 2024" className="event-image" />
  </p>
  <p>Uansett om du spiller for gøy eller går for seieren, er det plass til alle her. Finn frem racketen, knyt skoa og 
    bli med på moroa, og skap minner som varer!
  </p>
  <p>Du er hjertelig velkommen til å ta med din bedre halvdel, makker eller begge to!</p>
  <button onClick={() => setIframeVisible(true)}>Meld deg på i dag</button>
</section>
  
        <section>
          <h2>CUP MESTERE 2024</h2>
              {/* Inserted image */}
          <img src="/SC2024Winners.jpg" alt="Stake Cup 2024" className="event-image" />
          <p>
          Hvem kan glemme den intense finalen i 2024, der Fredrik og Stian til slutt sto igjen som de første 
          vinnerne av STAKE CUP etter en hard kamp mot Emil og Bjarne? Dette var en minneverdig dag for alle 
          som deltok og så på, og vi gleder oss til å se hvem som vil ta tittelen i år! 
          </p>
        </section>
  
        <section>
  <h2>PROGRAM</h2>
  <p>
    Her er foreøpig program for STAKE CUP 2025. Det er lagt opp til en bra dag med av aktivitet, og jeg oppfordrer 
    alle til å delta på både turneringen og banketten for å få den komplette opplevelsen. For de som ønsker 
    å bli med kun på turneringen eller kun på banketten, er det selvfølgelig også mulig. Datoen er 25.01.2025
  </p>

  {/* Styled program list */}
  <ul className="program-list">
    <li><span className="program-time">13:30</span> Oppmøtetid for spillere</li>
    <li><span className="program-time">14:00</span> Turneringen starter</li>
    <li><span className="program-time">16:00</span> B-finale</li>
    <li><span className="program-time">16:15</span> A-finale</li>
    <li><span className="program-time">17:00</span> Pause for dusj og skift</li>
    <li><span className="program-time">18:30</span> Banketten åpner</li>
    <li><span className="program-time">19:00</span> Middag</li>
  </ul>
</section>
  
<section>
  <h2>TURNERINGEN</h2>
  <p>
    Stake Cup 2025 spilles i klassisk padelformat, med en varighet på 2 timer, etterfulgt av to 15-minutters finaler 
    – én for hver gruppe. Forslaget er at vi deler inn i to grupper basert på påmelding, men dette kan endre seg. Mer info 
    vil komme nærmere turneringsdato. Turneringen spilles i 'vinnerbane'-format, der hvert spill varer i 10–15 minutter 
    før spillerne bytter bane basert på resultatet. Dette gir både høy intensitet og mye variasjon i spillet.
    </p>
    <p>
      Har du ikke makker, vil vi sette opp lag når det nærmer seg, og skulle du finne en etterhver, er det bare å gi beskjed!
    </p>
    <p>
      <i>Ps, det vil bli kåring av beste lag-antrekk/kostyme</i>
    </p>
  
  {/* Location Subsection with Google Maps Iframe */}
  <div className="location-section">
    <div className="location-info">
      <h3>Lokasjon</h3>
      <p><strong>Nordic Padel Rødtvet</strong></p>
      <p>Pottemakerveien 12, Oslo</p>
    </div>
    <iframe
      title="Stake Cup Venue Map"
      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d6720.105657922202!2d10.845593426087808!3d59.95040379107908!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x464171cefd69015d%3A0x4d4d374b58127a7b!2sNordic%20Padel%20%26%20Golf%20Arena%20avd%20Nord!5e0!3m2!1sno!2sno!4v1729878671888!5m2!1sno!2sno"
      width="100%"
      height="300"
      style={{ border: 0, borderRadius: '8px' }}
      allowFullScreen=""
      loading="lazy"
    ></iframe>
  </div>
</section>


  
        <section>
          <h2>BANKETT</h2>
          <p>
          Når turneringen er over, samles vi til bankett for å feire dagens prestasjoner med god mat, mye godt i glasset og 
          i veldig godt selskap. Deltakerne vil motta et skjema for matbestilling i forkant, da vi forventer mange påmeldte. 
          Etter middagen fortsetter kvelden på en pub i nærheten, hvor vi runder av STAKE CUP 2025 på best mulig vis.
          </p>

  {/* Bankett Location Subsection */}
  <div className="location-section">
    <div className="location-info">
      <h3>Lokasjon</h3>
      <p><strong>Info kommer</strong></p>
      <p></p>
    </div>
    <iframe
      title="Bankett Venue Map"
      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1000.0775258869863!2d10.735710678131383!3d59.912974039572454!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x46416e7d7e51e435%3A0xe3efe28735861531!2sStortingsgata%208%2C%200160%20Oslo!5e0!3m2!1sno!2sno!4v1729881197364!5m2!1sno!2sno"
      width="100%"
      height="300"
      style={{ border: 0, borderRadius: '8px' }}
      allowFullScreen=""
      loading="lazy"
      referrerPolicy="no-referrer-when-downgrade"
    ></iframe>
  </div>
</section>
  
        <section>
          <h2>MELD DEG PÅ</h2>
          <p>
          Vi håper du melder deg på så tidlig som mulig! Skulle det bli nødvendig, er det selvsagt mulig å melde seg av senere.
          </p>
          <button onClick={() => setIframeVisible(true)}>Meld deg på i dag</button>
        </section>

        <section>
          <h2>KONTAKT MEG</h2>
          <p>
            Har du spørsmål er det bare å ta kontakt med meg - 97091500.<br /><br />Mvh Stian Stake - VAMOS!!
          </p>
        </section>


  

          {/* Sliding Iframe for RSVP */}
          <div className={`iframe-container ${iframeVisible ? 'open' : ''}`}>
            <div className="iframe-content">
              {loading && <p>Loading registration...</p>}
              <iframe
                onLoad={() => setLoading(false)}
                src="https://events.humanitix.com/stake-cup-2025/tickets?widget=popup&accessCode=VAMOS"
                title="RSVP"
              ></iframe>
            </div>
          </div>

          {/* Overlay */}
          {iframeVisible && <div className="iframe-overlay" onClick={() => setIframeVisible(false)}></div>}
        </div>
      )}
    </div>
  );
};

export default App;
